.utilitiesAndMore,
.users-list {
	padding: 0.5rem;
	margin: 0.5rem;
	box-shadow: $box-shadow;
	.proCard {
		padding: 1rem 0;
		.avatar {
			border-radius: 50%;
			width: 106px;
			height: 106px;
			border: 1px solid #707070;
		}
		border-bottom: 1px solid #c3c3c3;
	}

	.btn-success,
	.btn-success:focus {
		color: #127e99;
		border: 1px solid #127e99;
		background: #fff;
	}
	.add-util {
		padding: 0.5rem;
		margin: 0.5rem;
		box-shadow: $box-shadow;
		.utilities-dropdown {
			color: $main-color;
			border: 1px solid $main-color;
			background: none !important;
		}
		label {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.avatar {
			width: 106px;
			height: 106px;
			border-radius: 50%;
		}
	}
}

.in-active-radio {
  .ant-radio-checked .ant-radio-inner {
    border-color: $danger-color !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: $danger-color;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $danger-color;
  }
}

.active-radio {
  .ant-radio-checked .ant-radio-inner {
    border-color: $success-color !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: $success-color;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $success-color;
  }
}

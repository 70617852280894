.contact-modal {
  &__header {
    display: flex;
    h5 {
      margin-inline-end: 2rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: $main-color;
    }
  }
  &__desc {
    display: flex;
    margin-bottom: 1.5rem;
    .name {
      margin-inline-end: 1rem;
    }
    .name,
    .email {
      font-size: 1rem;
      color: #707070;
      font-weight: 500;
    }
  }

  &__email {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #707070;
  }
  &__area {
    padding: 1rem 0.5rem;
    font-size: 0.95rem;
    margin-bottom: 1rem;
  }
  &__radios {
    span.ant-radio + * {
      font-size: 1rem;
      color: $main-color;
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
  &__select {
    display: flex;
    align-items: center;
    .by {
      font-size: 1rem;
      color: $main-color;
      font-weight: bold;
      margin-inline-end: 1rem;
    }
    .select {
      width: 250px;
    }
  }
  &__btn {
    margin-top: 2rem;
    text-align: center;
  }
}

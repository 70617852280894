.home {
  display: flex;
  flex: 1;
  width: 100%;
  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 20vw;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 20vw;
  }

  &__content {
    width: 76.5vw;
    margin-inline: 1vw;

    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
  }
}

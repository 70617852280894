.quotaions {
  &__files {
    padding: 1rem;
  }
  &__container {
    display: flex;
    align-items: baseline;
  }
  &__select {
    text-align: center;
    margin-inline-end: 1.5rem;
    align-self: flex-end;
  }
  &__uploader {
    width: 250px;
    .upload-btn {
      height: 2rem;
    }
  }
  &__submit {
    text-align: end;
  }

  .name {
    margin-inline-start: 1rem;
  }

  .submit-btn,
  .upload-btn {
    width: 250px;
  }
  .submit-btn {
    height: 2.5rem;
  }

  &__sizes {
    padding: 1rem;
    margin-top: 1.25rem;

    .size {
      &__item {
        margin-inline-end: 1.5rem;
        span {
          font-size: 1rem;
          font-weight: 400;
          color: $main-color;
        }
      }
      &__input {
        margin-inline: 1.5rem 0.5rem;
      }
    }
  }
}

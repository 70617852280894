.packages {
  flex: 1;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #707070;
  justify-content: center;
  align-items: center;
  width: 100%;

  .add-package {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__card {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid $main-color;
    position: relative;
    .edit-package {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      width: 1.35rem;
      svg {
        width: 100%;
        fill: $main-color;
      }
    }
  }

  &__title {
    color: $main-color;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    p {
      color: #707070;
      font-size: 1.5rem;
      margin-inline-end: 1rem;
      font-weight: bold;
      margin-bottom: 0 !important;
    }
  }

  &__name {
    color: $main-color;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-decoration: underline;
  }

  &__price {
    font-size: 25px;
    font-weight: bold;
    color: $main-color;
  }

  &__fig {
    width: 2rem;
    margin-bottom: 0;

    img {
      width: 100%;
    }
  }
  .no-data {
    display: flex;
    align-self: center;
    justify-self: center;
  }
  &__add-modal {
    label.ant-form-item-required {
      color: $main-color;
      font-weight: 600;
    }

    .ant-radio-wrapper span {
      color: $main-color;
      font-weight: 600;
    }
  }
}

.servery {
  &__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }
  &__container {
    padding: 1rem;
  }
  &__services {
    padding: 1rem;

    h5 {
      color: $main-color;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;
    border-bottom: 1px solid $main-color;
    padding-bottom: 1rem;
  }
  &__users {
    height: 200px;
    overflow-y: auto;
    position: relative;
    .user {
      color: $main-color;
      text-decoration: underline;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .border {
    margin-block: 1rem;
    border-color: $main-color !important;
  }
}

.transactions {
  &__date {
    margin-bottom: 52px;
  }
  &__head {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: $main-color;
    font-weight: bolder;
  }
  &__select {
    margin-bottom: 2rem;
  }
  &__section {
    padding: 1rem;
  }
  table {
    width: 100%;
    min-height: 43vh;
    position: relative;
    border: 1.5px soild #707070 !important;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%);
    }
    td {
      border: 1.5px solid #707070;
      padding: 1rem;
      text-align: center;
      font-size: 0.7rem;
      color: $main-color;

      &.td-head {
        font-size: 0.85rem;
        font-weight: bold;
        color: $main-color;
      }
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */
$main-color: #12819c;
$main-color-2: #2693ad;
$main-shadow: 8px 16px 32px #0000001a;
$black-border: #707070;
$danger-color: #ff0505;
$success-color: #2fb066;
$warn-color: #ffcc00;

/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */
$bold-sm: 400;
$bold-lg: 500;
$bolder: 700;
$boldest: 900;

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
$trans: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
$box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

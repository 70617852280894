.service {
  .multiple {
    padding: 1rem 1.25rem;
    &__radios {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }
  &__h5 {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    font-weight: bold;
    color: $main-color;
  }
  &__container {
    padding: 1rem 1.25rem;
  }
  &__drop-swtich {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .service {
      &__dropdown {
        width: 95px;
        color: $main-color;
        border: 2px solid $main-color;
        padding: 0.5em;
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 1rem;
        &__overlay {
          width: 95px;
          max-height: 250px;
          overflow-y: auto;
        }
        &.inbox {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }

  &__copy-delete-required {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img:first-of-type {
      margin-inline-end: 1rem;
    }
  }

  &__button {
    text-align: center;
    margin-top: 1.5rem;
  }

  &__switch-container {
    display: flex;
    align-items: center;
    div {
      margin-inline-end: 0.5rem;
      color: $main-color;
      font-size: 0.85rem;
      font-weight: 500;
      color: #897f7f;
    }
  }

  &__number {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #897f7f;
      margin-inline-end: 1rem;
    }
  }

  &__input {
    padding: 0.5rem 0.75rem;
    margin-bottom: 1.5rem;
    &:focus,
    &:visited,
    &:focus-visible,
    &:hover {
      outline: none;
    }
  }

  &__answers {
    margin-bottom: 2rem;
    .answer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1.25rem;
      &__input {
        margin-inline-end: 1rem;
        margin-right: 1rem;
        border: none;
        border-bottom: 1px solid $main-color;
        border-radius: 0;
        width: 98%;
        padding: 0.5rem;
        &:focus,
        &:visited,
        &:focus-visible,
        &:hover {
          outline: none;
          box-shadow: none;
        }
      }
      &__add {
        margin-bottom: 1rem;
        font-size: 0.85rem;
        font-weight: 400;
        color: #707070;

        img {
          margin-inline-end: 1rem;
        }
      }
    }
  }

  &__other {
    font-size: 0.85rem;
    font-weight: 400;

    div {
      margin-inline-start: 0.8rem;
      color: #707070;
    }
  }
}

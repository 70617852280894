.proService-modal {
	padding: 48px;
	&.ant-modal {
		padding-top: 0;
	}
	.ant-modal-body {
		height: 90vh;
		min-height: 450px;
		margin-top: 0;
		overflow: hidden;
	}
	&__profileData {
		.text {
			color: #333;
			font-weight: 500;
			margin-bottom: 8px;
		}
		padding-bottom: 8px;
		margin-bottom: 16px;
		border-bottom: 0.125rem solid #5fb4c9;
	}
	&__services-container {
		height: 320px;
		overflow: auto;
	}
	.service,
	.service-type {
		color: $main-color;
	}
	.service {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 1rem;
	}
	.service-type {
		font-size: 1.5rem;
		font-weight: 400;
		margin-bottom: 2px;
		margin-bottom: 1rem;
	}
	.service-type-items {
		.item {
			font-size: 18px;
			color: #333;
			font-weight: 400;
		}
	}
	.service-type-other {
		font-size: 18px;
		color: #333;
		font-weight: 400;
	}
	.questions {
		&__part {
			margin-bottom: 1rem;
			.question {
				color: $main-color;
				margin-bottom: 0.25rem;
				font-size: 18px;
				font-weight: bold;
			}
			.answer {
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
	.empty {
		position: absolute;
		top: 65%;
		left: 50%;
		color: gray;
		transform: translate(-50%, -50%);
		font-size: 1.5rem;
	}
}

.conversion {
  display: flex;
  flex: 1;
  margin-inline: auto;
  padding-inline: 2rem;
  padding-block: 2rem;
  align-items: center;
  text-align: center;
  min-width: 65%;

  &__form {
    width: 100%;
    padding-block: 1.5rem;
  }
  &__card {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
  &__fig {
    width: 4rem;
    margin-bottom: 0 !important;
    img {
      width: 100%;
      margin-inline-start: 1rem;
    }
  }
  &__text {
    color: #707070;
    font-size: 4rem;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    box-shadow: 0 0 0 2px transparent !important;
    align-self: baseline;
  }

  .ant-input {
    border-width: 0px !important;
    border-bottom: 1.2px solid #707070 !important;
    font-size: 1.75rem;
    color: #707070;
    border-radius: 0;
    width: 6.75rem;
    text-align: center;
    padding-inline: 0 !important;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: 0 0 0 2px transparent !important;
  }
}

.performance {
  &__row {
    margin-block: 1.5rem;
    max-width: 100%;
  }

  &__chart {
    padding: 1.5rem;
    position: relative;

    .chart__title {
      color: $main-color;
      text-align: center;
      margin-block: 1rem;
      font-size: 1.0625rem;
    }
    .chart__percent {
      position: absolute;
      top: 57%;
      left: 55%;
      transform: translate(-50%, -50%);
      font-size: 4rem;
      color: gray;
      z-index: -1;
    }
  }
}

.submitted {
  .ant-modal-body {
    width: 75vw;
    width: 100%;
  }

  &__wrapper {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-block: 1rem;
  }

  // width: 75vw;
  &__project-name {
    color: $main-color;
    font-size: 1.25rem;
    font-weight: 700;
    padding-block-end: 0.5rem;
    margin-block-end: 0.5rem;
    border-bottom: 1px solid $main-color;
  }

  .btn {
    font-size: 14px;
  }
  .title {
    color: #12819c !important;
  }
  .porperties {
    .project {
      h5 {
        color: $main-color;
      }
    }
    .measure {
      color: $main-color;
      font-weight: bold;
    }
    .budget h5,
    .time h5,
    .handover h5,
    .status,
    .invoice,
    .comments-title {
      color: $main-color;
      font: normal normal medium 16px/19px Montserrat;
    }
    .project-comments {
      p {
        color: #000;
      }
    }
    .button {
      background-color: $main-color;
      color: #fff;
      .download-icon {
        margin-inline-start: 10px;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .optional {
    box-shadow: $box-shadow;
    padding: 0.625rem;
    width: 99.5%;
    margin-left: auto;
    margin-right: auto;
    .title {
      font: normal normal bold 16px/19px Montserrat;
      .title-desc {
        margin-inline-end: 0.3125rem;
      }
      img {
        max-width: 9.375rem;
      }
    }
    h6 {
      color: #707070;
      font-weight: 500;
    }
  }
  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem !important;
  }
}

@include to-tablet-sm {
  .porperties {
    .button {
      width: 100%;
    }
  }

  .hide {
    display: none;
  }
}

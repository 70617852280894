.search-date {
	display: flex;
	justify-content: flex-end;
	align-items: baseline;
	margin-block: 1rem;
	&__signup-date {
		display: flex;
		align-items: end;
		line-height: 1;
	}
	.date {
		margin-inline-start: 1rem;
		min-width: 250px;
	}
	.add-admin {
		font-size: 1rem;
		display: flex;
		align-items: center;
		align-self: center;
		margin-inline-start: 1rem;

		svg {
			fill: $main-color;
			font-size: 1rem;
			margin-inline-end: 0.5rem;
		}

		span {
			color: $main-color;
			text-decoration: underline;
		}
	}
}
.users-modal {
	.ant-modal-body {
		padding-top: 3rem;
	}
	&__users {
		text-align: center;
		height: 50vh;
		overflow-y: auto;
		margin-block-start: 2rem;
		.item {
			margin-block: 0.75rem;
			color: #707070;
			font-size: 1rem;
			text-decoration: underline;
		}
	}
	.nav-link {
		color: $main-color;
	}
	.nav-tabs .nav-link:focus,
	.nav-tabs .nav-link:hover,
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		border-color: transparent;
		color: $main-color;
	}

	.nav-tabs .nav-link.active {
		font-weight: bold;
	}
}

.anticon-close.ant-modal-close-icon {
	svg {
		fill: $main-color;
	}
}

.nav-tabs {
	border-bottom: 0px;
}

.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
	display: none;
}

$box-shadow: 0px 6px 12px #00000029;
.proRatings-modal {
	.ant-modal-body {
		height: 550px;
		background-color: white;
	}
	&__container {
		height: 430px;
		overflow: auto;
		margin-top: 1.75rem;
	}
	&__preview {
		padding: 1rem;
		padding-top: 1rem;
	}

	.review {
		box-shadow: $box-shadow;
		padding: 0.75rem 1rem;
		margin-bottom: 1rem;

		&__rate-date {
			margin-bottom: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.rates-date {
				display: flex;
				align-items: center;
				.date {
					margin-inline-end: 0.5rem;
					font-size: 1rem;
					color: #707070;
				}
				.rate {
					&__name {
						margin-bottom: 0.25rem;
						text-align: center;
					}
					&__value {
						margin-bottom: 0.5rem;
						border: 1px solid red;
					}
				}
			}
		}
		&__comment {
			margin-bottom: 0.5rem;
			font-size: 1rem;
		}
		&__client-delete {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			.by {
				font-size: 0.75rem;
				color: gray;
				font-weight: 600;
			}
			.delete {
				display: flex;
				align-items: baseline;
				color: red;
				font-size: 0.8rem;
				img {
					margin-inline-end: 0.5rem;
				}
			}
		}
	}
	.empty {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.5rem;
		font-weight: 400;
		color: gray;
	}
}
